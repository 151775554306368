<template>
  <div class="visits">
    <div class="visits__months" v-if="current">
      <div class="prev">
        <vs-button
          type="flat"
          color="dark"
          icon="chevron_left"
          @click="downMonth"
        ></vs-button>
      </div>
      <div class="month-name">{{ current.name }}</div>
      <div class="next">
        <vs-button
          type="flat"
          color="dark"
          icon="chevron_right"
          @click="upMonth"
        ></vs-button>
      </div>
    </div>
    <AttendanceTable :data="data" size="small" />
    <div class="current-static">
      <div class="current-static__item">
        <span>Всего занятий проведено:</span>
        <span>
          <strong>{{ currentStatic.allLessonsInMonth || '-' }}</strong>
        </span>
      </div>
      <div class="current-static__item">
        <span>Всего дней отсутствовал:</span>
        <span>
          <strong>{{ currentStatic.missingInMonth || '-' }}</strong>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AttendanceTable from '@/components/visits/attendanceTable'
import getTeacherStat from '@/api/teachers/getTeacherStat'

export default {
  name: 'visits',
  props: ['teacher', 'missings', 'schoolyearId'],
  data() {
    return {
      data: {},
      pages: [],
      current: undefined,
      today: new Date(),
      prevMonthStat: {},
      lessons: {},
      groups: [],
      missingDays: {},
      currentStatic: {},
    }
  },
  components: { AttendanceTable },
  computed: {
    ...mapState({
      yearMonths: state => state.years.yearMonths,
      currentYear: state => state.years.currentSchoolYear,
      years: state => state.years.years,
    }),
    schoolyear: function() {
      if (this.years.length)
        return this.years.find(y => y.id == this.schoolyearId)
      return {}
    },
  },
  methods: {
    getLessonsByTeacher: function() {
      const min_date = this.current.monthObject.startOf('month').unix()
      const max_date = this.current.monthObject.endOf('month').unix()

      const query = {
        min_date,
        max_date,
      }
      return getTeacherStat
        .call(this, { id: this.teacher.id, query })
        .then(response => {
          if (response.status == 'OK') {
            this.lessons = response.data
          }
        })
    },
    getAttendancePages: function() {
      if (this.yearMonths.length) {
        this.pages = this.yearMonths.map((m, index) => {
          const month = this.$moment(m * 1000)
          const page = {
            index,
            name: month.format('MMMM YYYY'),
            year: month.format('YYYY'),
            current:
              month.month() == this.today.getMonth() &&
              month.year() == this.today.getFullYear(),
            month: month.month(),
            monthObject: month,
          }
          if (page.current) this.current = page
          return page
        })
        if (typeof this.current === 'undefined' || this.current === null) {
          const schoolYearStartDate = new Date(
            this.schoolyear.date_start_unix * 1000
          )
          if (schoolYearStartDate.getMonth() + 1 == this.pages[0].month) {
            this.current = this.pages[0]
          } else {
            this.current = this.pages[this.pages.length - 1]
          }
        }
      }
      return []
    },
    getMissingDays: function() {
      let days = {}
      this.missings.forEach(m => {
        var now = m.date_start.clone()
        var end = m.date_end

        while (now.isSameOrBefore(end)) {
          days[now.format('DD.MM.YYYY')] = m
          now.add(1, 'days')
        }
      })
      this.missingDays = days
      return days
    },
    getAttendanceLessons: function() {
      const data = {
        thead: [{ value: '' }],
        rows: [
          { columns: [{ value: 'group' }] },
          { columns: [{ value: 'evening' }] },
          { columns: [{ value: '1.5 hours' }] },
          { columns: [{ value: '2.5 hours' }] },
          { columns: [{ value: 'tutor' }] },
          { columns: [{ value: 'assistant' }] },
          { columns: [{ value: 'testing' }] },
          { columns: [{ value: 'replacements' }] },
        ],
      }
      let allLessonCount = 0,
        allGroupCount = 0,
        allEventCount = 0,
        allTutorCount = 0,
        allLongCount = 0,
        allBigCount = 0,
        allAssistant = 0,
        allTesting = 0,
        allReplacements = 0,
        missingInMonth = 0

      for (var day in this.lessons) {
        const stat = this.lessons[day]
        const parsed_day = this.$moment(day)
        let status =
          parsed_day.weekday() == 0 || parsed_day.weekday() == 6
            ? 'wrong'
            : null
        let comment = null
        data.thead.push({
          value: parsed_day.format('DD'),
          status,
          comment,
        })
        data.rows[0].columns.push({ value: stat.group || '', status })
        data.rows[1].columns.push({ value: stat.evening || '', status })
        data.rows[2].columns.push({ value: stat.long || '', status })
        data.rows[3].columns.push({ value: stat.big || '', status })
        data.rows[4].columns.push({ value: stat.tutor || '', status })
        data.rows[5].columns.push({ value: stat.assistant || '', status })
        data.rows[6].columns.push({ value: stat.testing || '', status })
        data.rows[7].columns.push({ value: stat.replacements || '', status })
        allLessonCount +=
          stat.group + stat.long + stat.big + stat.tutor + stat.testing
        allGroupCount += stat.group
        allEventCount += stat.evening
        allLongCount += stat.long
        allBigCount += stat.big
        allTutorCount += stat.tutor
        allAssistant += stat.assistant
        allTesting += stat.testing
        allReplacements += stat.replacements
      }
      data.thead.push({
        value: 'count',
      })
      data.rows[0].columns.push({
        value: allGroupCount,
        columnType: 'count',
      })
      data.rows[1].columns.push({
        value: allEventCount,
        columnType: 'count',
      })
      data.rows[2].columns.push({
        value: allLongCount,
        columnType: 'count',
      })
      data.rows[3].columns.push({
        value: allBigCount,
        columnType: 'count',
      })
      data.rows[4].columns.push({
        value: allTutorCount,
        columnType: 'count',
      })
      data.rows[5].columns.push({
        value: allAssistant,
        columnType: 'count',
      })
      data.rows[6].columns.push({
        value: allTesting,
        columnType: 'count',
      })
      data.rows[7].columns.push({
        value: allReplacements,
        columnType: 'count',
      })
      this.currentStatic.allLessonsInMonth = allLessonCount
      this.currentStatic.missingInMonth = missingInMonth
      return data
    },
    getStatOfPrevMonth: function() {
      const prevMonth = this.pages[this.current.index - 1]
      if (prevMonth) {
        const prevMonthLessons = this.lessons.filter(
          l => new Date(l.lesson_time * 1000).getMonth() + 1 == prevMonth.month
        )
        let allCount = prevMonthLessons.length,
          cancelledCount = 0,
          doneCount = 0

        cancelledCount = prevMonthLessons.reduce(
          (a, b) => (b.cancelled ? a + 1 : a),
          0
        )
        doneCount = prevMonthLessons.reduce(
          (a, b) => (b.date_end ? a + 1 : a),
          0
        )
        this.prevMonthStat = {
          monthName: prevMonth.name,
          allCount,
          doneCount,
          cancelledCount,
        }
      } else {
        this.prevMonthStat = {}
      }
    },
    downMonth: function() {
      const index = this.current.index
      if (index > 0) {
        this.current = this.pages[index - 1]
        this.setLessons()
      }
    },
    upMonth: function() {
      const index = this.current.index
      if (index + 1 < this.pages.length) {
        this.current = this.pages[index + 1]
        this.setLessons()
      }
    },
    setPage() {
      this.current = null
      this.getAttendancePages()
      this.setLessons()
    },
    setLessons() {
      this.getLessonsByTeacher().then(() => {
        this.data = this.getAttendanceLessons()
      })
    },
  },
  watch: {
    teacher: function(newV) {
      if (!this.current || !this.current.monthObject) return
      if (newV) {
        this.setLessons()
      }
    },
    missings: function(newV, oldV) {
      if (!this.current || !this.current.monthObject) return
      if (newV && newV != oldV) {
        this.setLessons()
      }
    },
    schoolyearId: async function(newV) {
      await this.$store.dispatch('years/getYearMonthsAction', newV)
      this.setPage()
    },
  },
  created: async function() {
    if (this.schoolyearId != null)
      await this.$store.dispatch('years/getYearMonthsAction', this.schoolyearId)
    this.setPage()
  },
}
</script>
